import React from "react";
import Layout from "../components/layout/Layout";
import Heroimagebig from "../assets/hero-bg.png";
import Heroimagesmall from "../assets/hero-bg-mobile.png";
import {
  IoIosArrowDown,
  IoMdHand,
  IoIosBrush,
  IoMdWifi,
  IoMdBulb,
  IoIosQuote,
} from "react-icons/io";
import {
  FaInstagramSquare,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import Contact from "../assets/Contact-main.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Team6 from "../assets/team6.png";
import Team2 from "../assets/team2.png";
import Team3 from "../assets/team3.png";
import Team4 from "../assets/team4.png";
import "./Hero.css";
import Product1 from "../assets/Product-1.png";
import Product2 from "../assets/Product-2.png";
import About from "../assets/About-main.png";
import loop from "../assets/loop.png";
import Askgenie from "../assets/askgenie.png";
import Service1 from "../assets/Service-1.png";
import Service2 from "../assets/Service-2.png";
import Service3 from "../assets/Service-3.png";
import Client1 from "../assets/client-1.png";
import Client2 from "../assets/client-2.png";
import Client3 from "../assets/client-3.png";
import Client4 from "../assets/client-4.png";
import Client5 from "../assets/client-5.png";
import Client6 from "../assets/client-6.png";
import Client7 from "../assets/client-7.png";
import Client8 from "../assets/client-8.png";
import portfolio1 from "../assets/portfolio-1.png";
import portfolio2 from "../assets/portfolio-2.png";
import portfolio3 from "../assets/portfolio-3.png";
import { NavLink } from "react-router-dom";

const Hero = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Layout title={"Decooders/Home"}>
      <section className="Hero">
        <div className="container">
          <img src={Heroimagebig} alt="hero-image" className="hero-big w-100" />
          <img
            src={Heroimagesmall}
            alt="hero-image"
            className="hero-small w-100"
          />
          <div className="hero-text">
            Decooders is a UI/UX design and developing Company in Hyderabad.
            <div className="down-icon">
              <IoIosArrowDown />
            </div>
          </div>
        </div>
      </section>
      {/* About */}
      <section className="Main-About">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p className="small-text">
                What we are?
                <h1 className="large-text">
                  The Story Of Decood<span className="color-text">ers.</span>
                </h1>
              </p>
            </div>
          </div>
        </div>
        <div className="container about-div">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="about-content">
                Welcome to Decooders, a cutting-edge web and app development
                startup headquartered in the vibrant city of Hyderabad.
                Established in May 2022 by the visionary entrepreneur Subhani
                Sayyad, our company is committed to delivering innovative and
                high-quality digital solutions that empower businesses and
                individuals alike.
                <br /> <br />
                At Decooders, we envision a digital landscape where creativity
                meets functionality, and user experiences are not just crafted
                but curated. Our aim is to be at the forefront of technological
                advancements, providing bespoke web and app solutions that
                redefine industry standards.
                <br /> <br />
                Subhani Sayyad, the driving force behind our journey, is a
                seasoned professional with a passion for transforming ideas into
                digital reality. With a wealth of experience in the technology
                sector, Subhani brings leadership, innovation, and a relentless
                pursuit of excellence to Decooders. His vision is to build a
                company that not only thrives on technical expertise but also
                fosters a culture of collaboration, creativity, and client
                satisfaction.
              </div>
              <a href="/about" className="meet-text">
                Meet Our Decooders....
                <IoMdHand />
              </a>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <img
                src={About}
                alt="about-image"
                className="About-image w-100"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Long Strip */}
      <section className="Long-Strip">
        <div className="container">
          <div className="row number-content">
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div className="Number">2+</div>
              <p className="num-text">Years</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div className="Number">20+</div>
              <p className="num-text">Projects</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div className="Number">150+</div>
              <p className="num-text">End Users</p>
            </div>
          </div>
        </div>
      </section>
      {/* Products */}
      <section className="Product">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p className="small-text">
                Our Products
                <h1 className="large-text">
                  Our Upcoming <span className="color-text">Products.</span>
                </h1>
              </p>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <Slider {...settings}>
                <div className="product-slide">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <img
                          src={Product1}
                          className="project-img w-100"
                          alt="project image"
                        />
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-6 client-say"></div>
                      <div className="col-lg-7 col-md-8 col-sm-12 client-say">
                        <img src={loop} alt="loop" className="Loop w-25" />

                        <div className="Loop-text">
                          1. Unmatched Convenience: Loop understands that every
                          journey is unique. That's why we provide a trio of
                          transportation options - cabs, bikes, and autos.
                          Whether you prefer the comfort of a cab, the agility
                          of a bike, or the cost-effectiveness of an auto, Loop
                          has you covered.
                          <br />
                          <br />
                          2. City-Wide Accessibility: No matter where you are in
                          Hyderabad, Loop is there for you. Our extensive
                          network of drivers ensures that you can access our
                          services in every nook and corner of the city, making
                          Loop the go-to choice for urban travel.
                          <br />
                          <br />
                          3. Affordable and Transparent Pricing: Loop believes
                          in fair and transparent pricing. Know your fare
                          upfront, and enjoy affordable rates for every ride. No
                          hidden charges, no surprises – just straightforward
                          and honest pricing.
                        </div>
                        <div href="" className="meet-text">
                          View More....
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-slide">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <img
                          src={Product2}
                          className="project-img w-100"
                          alt="project image"
                        />
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-6 client-say"></div>
                      <div className="col-lg-7 col-md-7 col-sm-12 client-say">
                        <img src={Askgenie} alt="loop" className="Loop w-25" />

                        <div className="Loop-text">
                          Say hello to convenience like never before with Ask
                          Genie Everything, your all-in-one delivery app
                          designed to bring the world to your doorstep. Whether
                          it's a quick snack, groceries for the week, or a
                          last-minute gift, Ask Genie Everything is here to
                          grant your every wish!
                          <br />
                          <br />
                          1. Endless Possibilities, One App: Why settle for just
                          one type of delivery when you can have it all? With
                          Ask Genie Everything, the possibilities are endless.
                          From food and groceries to essentials and surprises,
                          our app is your magic lamp for instant, hassle-free
                          deliveries.
                          <br />
                          <br />
                          2. Lightning-Fast Delivery: We understand that time is
                          of the essence. Ask Genie Everything is committed to
                          delivering your requests at the speed of magic.
                          Experience lightning-fast delivery services that bring
                          your desired items to your doorstep before you even
                          finish making your wish.
                        </div>
                        <div href="" className="meet-text">
                          View More....
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section className="Our-Services">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p className="small-text">
                Our Services
                <h1 className="large-text">
                  Services from Decood<span className="color-text">ers.</span>
                </h1>
              </p>
            </div>
          </div>
        </div>

        <div className="container about-div">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="meet-text">
                <IoIosBrush /> Design
              </div>
              <br />
              <div className="Service-content">
                Welcome to Decooders, where innovation meets design excellence.
                In the rapidly evolving digital landscape, user experience (UX)
                and user interface (UI) design play pivotal roles in capturing
                and retaining the attention of your audience. At Decooders, we
                specialize in crafting seamless and visually stunning digital
                experiences that resonate with your brand identity.
                <br />
                <br />
                Our team of seasoned UX/UI designers possesses a wealth of
                experience in transforming complex ideas into intuitive and
                engaging user interfaces. We are committed to delivering designs
                that not only look good but also elevate the overall user
                experience.
                <br />
                <br />
                In the dynamic world of technology, staying ahead requires
                constant innovation. Decooders is dedicated to pushing
                boundaries and exploring new design paradigms. From wireframes
                to interactive prototypes, our designs are not just
                aesthetically pleasing but also functionally robust.
              </div>
              <p className="sub-point">
                UI Design /<span className="pad">Ux Design/</span>
                <span className="pad">Illustrations Design</span>
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <img
                src={Service1}
                alt="about-image"
                className="Service-image w-100"
              />
            </div>
          </div>
        </div>

        <div className="container about-div">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <img
                src={Service2}
                alt="about-image"
                className="Service-image w-100"
              />
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="meet-text">
                <IoMdWifi /> Technology
              </div>
              <br />
              <div className="Service-content">
                1. Expertise that Drives Results: Decooders boasts a team of
                seasoned developers with a proven track record of delivering
                high-performance web and app solutions. Our expertise spans a
                wide range of technologies, ensuring that your project is in the
                hands of professionals who are passionate about turning ideas
                into reality.
                <br />
                <br />
                2. Custom Solutions Tailored to You: We understand that each
                business is unique, and cookie-cutter solutions don't cut it.
                Our development process is highly collaborative. We work closely
                with you to understand your business objectives, ensuring that
                our solutions align perfectly with your vision and goals.
                <br />
                <br />
                3. Innovation at the Core: Innovation is the heartbeat of
                Decooders. We thrive on pushing technological boundaries and
                staying ahead of industry trends. Whether it's the latest web
                development frameworks or cutting-edge mobile app technologies,
                we're committed to providing you with solutions that stand out
                in the digital landscape.
              </div>
              <p className="sub-point">
                App Development /<span className="pad">Web Development/</span>
                <span className="pad">Software Development</span>
              </p>
            </div>
          </div>
        </div>
        <div className="container about-div">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="meet-text">
                <IoMdBulb /> Business
              </div>
              <br />
              <div className="Service-content">
                Welcome to Decooders, where innovation meets design excellence.
                In the rapidly evolving digital landscape, user experience (UX)
                and user interface (UI) design play pivotal roles in capturing
                and retaining the attention of your audience. At Decooders, we
                specialize in crafting seamless and visually stunning digital
                experiences that resonate with your brand identity.
                <br />
                <br />
                Our team of seasoned UX/UI designers possesses a wealth of
                experience in transforming complex ideas into intuitive and
                engaging user interfaces. We are committed to delivering designs
                that not only look good but also elevate the overall user
                experience.
                <br />
                <br />
                In the dynamic world of technology, staying ahead requires
                constant innovation. Decooders is dedicated to pushing
                boundaries and exploring new design paradigms. From wireframes
                to interactive prototypes, our designs are not just
                aesthetically pleasing but also functionally robust.
              </div>
              <p className="sub-point">
                Branding /<span className="pad">Digital Marketing/</span>
                <span className="pad">SEO</span>
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <img
                src={Service3}
                alt="Service-image"
                className="About-image w-100"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="Our-Customers">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p className="small-text">
                Our Projects
                <h1 className="large-text">
                  Our Valued Custom<span className="color-text">ers.</span>
                </h1>
              </p>
            </div>
          </div>
        </div>
        <div className="container client-div">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 ">
              <img src={Client1} alt="Clent-image" className="client w-50" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <img src={Client2} alt="Clent-image" className="client w-50" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <img src={Client3} alt="Clent-image" className="client w-50" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <img src={Client4} alt="Clent-image" className="client w-50" />
            </div>
            <br />
            <hr />
            <div className="col-lg-3 col-md-3 col-sm-6">
              <img src={Client5} alt="Clent-image" className="client w-50" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <img src={Client6} alt="Clent-image" className="client w-50" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <img src={Client7} alt="Clent-image" className="client w-50" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <img src={Client8} alt="Clent-image" className="client w-50" />
            </div>
          </div>
        </div>
      </section>
      {/* Teximonial */}
      <section className="Testimonial">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <Slider {...settings}>
                <div className="textimonial-box">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <img
                          src={Team2}
                          className="clent-img w-50"
                          alt="project image"
                        />
                        <div className="Text-name">Vamshi Krishna</div>
                        <p className="text-des">CEO-SIXPEP</p>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-12 client-say">
                        <div className="Test-head">
                          What Client <span className="redtext">Say's</span>
                        </div>
                        <div className="quote">
                          <IoIosQuote />
                        </div>
                        <div className="Test-para">
                          You're looking for a web and app design and
                          development partner in Hyderabad that combines
                          technical proficiency with a creative edge, Decooders
                          is the team to choose. Their passion for innovation,
                          commitment to excellence, and dedication to client
                          success make them an invaluable asset for any
                          business.
                        </div>
                        <span className="quote">
                          <IoIosQuote />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="textimonial-box">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <img
                          src={Team3}
                          className="clent-img w-50"
                          alt="project image"
                        />
                        <div className="Text-name">Jaya Teja</div>
                        <p className="text-des">CEO-Yrall</p>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-12 client-say">
                        <div className="Test-head">
                          What Client <span className="redtext">Say's</span>
                        </div>
                        <div className="quote">
                          <IoIosQuote />
                        </div>
                        <div className="Test-para">
                          The impact of Decooders' work is evident in the
                          positive feedback we've received from our users. The
                          intuitive designs and seamless functionality have
                          elevated the user experience, resulting in increased
                          engagement and customer satisfaction.
                        </div>
                        <span className="quote">
                          <IoIosQuote />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="textimonial-box">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <img
                          src={Team4}
                          className="clent-img w-50"
                          alt="project image"
                        />
                        <div className="Text-name">Olubiya Osuloyi</div>
                        <p className="text-des">CEO-Esportscave</p>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-12 client-say">
                        <div className="Test-head">
                          What Client <span className="redtext">Say's</span>
                        </div>
                        <div className="quote">
                          <IoIosQuote />
                        </div>
                        <div className="Test-para">
                          Their team, led by Subhani Sayyad, not only possesses
                          a deep understanding of the latest technologies but
                          also has a keen eye for design. The collaborative
                          approach they took ensured that our ideas were not
                          only understood but enhanced through their expertise.
                        </div>
                        <span className="quote">
                          <IoIosQuote />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="textimonial-box">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <img
                          src={Team6}
                          className="clent-img w-50"
                          alt="project image"
                        />
                        <div className="Text-name">Pavan</div>
                        <p className="text-des">CEO-Srivani Stores</p>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-12 client-say">
                        <div className="Test-head">
                          What Client <span className="redtext">Say's</span>
                        </div>
                        <div className="quote">
                          <IoIosQuote />
                        </div>
                        <div className="Test-para">
                          Working with Decooders has been an absolute
                          game-changer for our business. As a startup in
                          Hyderabad, we were in search of a web and app
                          development partner who not only understood our vision
                          but could bring it to life with creativity and
                          technical excellence. Decooders exceeded our
                          expectations in every way.
                        </div>
                        <span className="quote">
                          <IoIosQuote />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* Portfolio */}
      <section className="Our-Portfolio">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p className="small-text">
                Our Portfolio
                <h1 className="large-text">
                  A taste of our <span className="color-text">Portfolio.</span>
                </h1>
              </p>
            </div>
          </div>
        </div>
        <div className="Portfolio">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <img
                  src={portfolio1}
                  className="client-logo w-75"
                  alt="client-logo"
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <img
                  src={portfolio2}
                  className="client-logo w-75"
                  alt="client-logo"
                />
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <img
                  src={portfolio3}
                  className="client-logo w-75"
                  alt="client-logo"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Our-Contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p className="small-text">
                Let's Talk
                <h1 className="large-text">
                  Don't be Shy - <span className="color-text">Say Hi!</span>
                </h1>
              </p>
            </div>
          </div>
        </div>
        <div className="container cnt-div">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="contact-big">
                just want to <span className="red">say hello,</span>
                <br />
                we're here and ready to listen.
              </div>
              <div className="mail">hello@decooders.in</div>
              <div className="btn-ctc">
                <NavLink to="/contact">
                  <button type="button" className="btn talk">
                    Let's Talk
                  </button>
                </NavLink>
              </div>
              <div className="ctc-txt">Hyderabad, Telangana-500081</div>
              <div className="social-icons">
                <a href="https://www.instagram.com/decooders/" target="_blank">
                  <FaInstagramSquare />
                </a>
                <a href="/" target="">
                  <FaFacebookF />
                </a>
                <a href="https://twitter.com/Decooders" target="_blank">
                  <FaTwitter />
                </a>
                <a href="https://www.linkedin.com/decooders/" target="_blank">
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div>
                <img
                  src={Contact}
                  alt="Contact-image"
                  className="cntct-image w-75"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
      </section>
    </Layout>
  );
};

export default Hero;
