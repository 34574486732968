import React from "react";
import Layout from "../components/layout/Layout";
import "./Policy.css";

const Policy = () => {
  return (
    <Layout title={"Privacy Policies"}>
      <section className="Policy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 prvcystrp">
              <div className="Policy-Headingmain">TERMS & CONDITIONS</div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="Policy-text">
                <b>
                  Terms and Conditions for Decooders Web and App Development:
                </b>
                <br /> 1. Acceptance of Terms: By engaging with Decooders for
                web and app development services, you agree to abide by these
                terms and conditions.
                <br /> 2. Services: Scope: Decooders will provide web and app
                development services as per the mutually agreed-upon scope of
                work. Modifications: Changes to the project scope must be
                documented and may result in adjustments to timelines and costs.
                <br />
                3. Project Initiation: Requirements: Clients must provide
                necessary information, assets, and approvals for the project to
                commence. Timelines: Timelines are estimates and may be subject
                to change based on project complexity and client responsiveness.
                <br />
                4. Fees and Payment: Quotations: All project quotations are
                valid for a specified period. Prices may be subject to change
                after this period. Invoicing: Invoices will be issued based on
                project milestones. Full payment is due within 20 days of the
                invoice date.
                <br /> 5. Intellectual Property: Ownership: Upon full payment,
                clients will own the intellectual property rights to the
                developed web and app solutions. Third-Party Components:
                Decooders may use third-party components with appropriate
                licenses.
                <br /> 6. Confidentiality: Non-Disclosure: Both parties agree to
                keep confidential information exchanged during the project
                confidential. Exceptions: Information already in the public
                domain or independently developed may not be subject to
                confidentiality. <br />
                7. Communication: Project Manager: Each party will designate a
                project manager for effective communication. Updates: Regular
                project updates will be provided, and client feedback is crucial
                for project progress. <br />
                8. Warranties and Support: Warranty Period: A 15-day warranty
                period is provided for bug fixes and issues identified after
                project completion. Support: Additional support and maintenance
                services can be provided upon agreement. <br />
                9. Termination: Termination Clause: Either party may terminate
                the agreement with written notice in the event of a material
                breach. Outstanding Payments: Termination does not release the
                client from their obligation to pay for completed work. <br />
                10. Governing Law: Jurisdiction: These terms and conditions are
                governed by the laws of the jurisdiction of Hyderabad, India.{" "}
                <br />
                11. Amendments: Changes: Decooders reserves the right to update
                these terms and conditions. Clients will be notified of any
                changes.
                <br /> 12. Contact Information: Communication: All
                communications regarding these terms should be directed to
                hello@decooders.in.
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 prvcystrp">
              <div className="Policy-Headingmain">PRIVACY POLICY</div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="Policy-text">
                Thank you for choosing Decooders for your web and app design and
                development needs. This Privacy Policy outlines how we collect,
                use, disclose, and protect your personal information. By using
                our website and services, you agree to the terms outlined in
                this policy. <br />
                1. Information We Collect: Personal Information: We may collect
                personal information such as names, email addresses, and contact
                details when you voluntarily provide them to us. Usage Data: We
                collect information about how you interact with our website and
                services, including IP addresses, device information, and
                browsing history. <br />
                2. How We Use Your Information: We use the collected information
                for the following purposes: Providing Services: To deliver the
                web and app design and development services you request.
                Communication: To respond to your inquiries, provide updates,
                and communicate important information. Improvement of Services:
                To enhance and improve our services based on user feedback and
                interactions. <br />
                3. Information Sharing: We do not sell, trade, or otherwise
                transfer your personal information to third parties without your
                consent, except as outlined in this Privacy Policy or when
                required by law. Service Providers: We may share information
                with trusted third-party service providers who assist us in
                delivering our services. Legal Compliance: We may disclose
                information when required by law or to protect our rights,
                privacy, safety, or property. <br />
                4. Security: We implement industry-standard security measures to
                protect your personal information from unauthorized access,
                disclosure, alteration, and destruction. <br />
                5. Cookies: Our website may use cookies to enhance your browsing
                experience. You can control cookie preferences through your
                browser settings.
                <br /> 6. Your Choices: You have the right to: Access, correct,
                or delete your personal information. Opt-out of receiving
                communication from us. Disable cookies through your browser
                settings. <br />
                7. Changes to Privacy Policy: We may update our Privacy Policy
                periodically. Any changes will be reflected on this page with an
                updated effective date.
                <br /> 8. Contact Information: If you have any questions or
                concerns about this Privacy Policy, please contact us at
                hello@decooders.in.
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </Layout>
  );
};

export default Policy;
