import React from "react";
import Layout from "../components/layout/Layout";
import About2 from "../assets/About-main.png";
import "./About.css";
import { IoMdSearch, IoMdMedal, IoIosQuote } from "react-icons/io";
import { GoGoal } from "react-icons/go";
import ceo from "../assets/CEO.png";
import { FaTwitter, FaLinkedinIn } from "react-icons/fa";
import Team1 from "../assets/team1.png";
import Team2 from "../assets/team2.png";
import Team3 from "../assets/team3.png";
import Team4 from "../assets/team4.png";
import Team5 from "../assets/team5.png";
import Team6 from "../assets/team6.png";

const About = () => {
  return (
    <Layout title={"Decooders/About"}>
      {/* About */}
      <section className="Main-About">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p className="small-text">
                What we are?
                <h1 className="large-text">
                  The Story Of Decood<span className="color-text">ers.</span>
                </h1>
              </p>
            </div>
          </div>
        </div>
        <div className="container about-div">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="about-content">
                Welcome to Decooders, a cutting-edge web and app development
                startup headquartered in the vibrant city of Hyderabad.
                Established in May 2022 by the visionary entrepreneur Subhani
                Sayyad, our company is committed to delivering innovative and
                high-quality digital solutions that empower businesses and
                individuals alike.
                <br /> <br />
                At Decooders, we envision a digital landscape where creativity
                meets functionality, and user experiences are not just crafted
                but curated. Our aim is to be at the forefront of technological
                advancements, providing bespoke web and app solutions that
                redefine industry standards.
                <br /> <br />
                Subhani Sayyad, the driving force behind our journey, is a
                seasoned professional with a passion for transforming ideas into
                digital reality. With a wealth of experience in the technology
                sector, Subhani brings leadership, innovation, and a relentless
                pursuit of excellence to Decooders. His vision is to build a
                company that not only thrives on technical expertise but also
                fosters a culture of collaboration, creativity, and client
                satisfaction.
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <img
                src={About2}
                alt="about-image"
                className="About-image w-75"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Long Strip */}
      <section className="Long-Strip">
        <div className="container">
          <div className="row number-content">
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div className="Number">2+</div>
              <p className="num-text">Years</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div className="Number">20+</div>
              <p className="num-text">Projects</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div className="Number">150+</div>
              <p className="num-text">End Users</p>
            </div>
          </div>
        </div>
      </section>
      {/* Vision */}
      <section className="Vision">
        <div className="container">
          <div className="row number-content">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="v-icon">
                <IoMdSearch />
              </div>
              <div className="vision-head">Vission</div>
              <p className="vision-text">
                Keep the end-user at the center of everything we do. Design and
                develop solutions that not only meet business objectives but
                also provide exceptional user experiences.
              </p>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="v-icon">
                <GoGoal />
              </div>
              <div className="vision-head">Mission</div>
              <p className="vision-text">
                Strive for excellence in every project, setting the industry
                standard for creative design, cutting-edge technology, and
                flawless execution.
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="v-icon">
                <IoMdMedal />
              </div>
              <div className="vision-head">Value</div>
              <p className="vision-text">
                Encourage a culture of creativity where every team member is
                empowered to think outside the box, push boundaries, and bring
                fresh ideas to the table.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* CEO BOX */}
      <section className="CEO">
        <div className="container CEO-BOX">
          <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-12">
              <div className="quote">
                <IoIosQuote />
              </div>
              <div className="my-name">Subhani Sayyad</div>
              <div className="digi">CE0 & Co-Founder</div>
              <div className="ceo-txt">
                Greetings! I'm Subhani Sayyad, the CEO of Decooders, a
                forward-thinking web and app development startup based in the
                vibrant city of Hyderabad. With a passion for technology and a
                background as a web developer since January 2020, I've embarked
                on a journey to redefine digital experiences and bring
                innovation to the forefront of the industry.
              </div>
              <div className="social-icons">
                <a href="https://twitter.com/SubhaniSayyad11" target="_blank">
                  <FaTwitter />
                </a>
                <a
                  href="https://www.linkedin.com/in/subhani-sayyad-926125269/"
                  target="_blank"
                >
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12"></div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="subbu">
                <img src={ceo} alt="Contact-image" className="ceo-image w-50" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Team */}
      <section className="Main-About">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p className="small-text">
                Who we are?
                <h1 className="large-text">
                  We are Decood<span className="color-text">ers.</span>
                </h1>
              </p>
            </div>
          </div>
        </div>
        <div className="Portfolio">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="team-card">
                  <img
                    src={Team1}
                    className="team-img w-50"
                    alt="client-logo"
                  />
                  <div className="team-name"> Neha.Ch</div>
                  <div className="team-des"> HR & Content Writer</div>
                  <div className="social-icons">
                    <a href="" target="">
                      <FaTwitter />
                    </a>
                    <a href="" target="">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="team-card">
                  <img
                    src={Team2}
                    className="team-img w-50"
                    alt="client-logo"
                  />
                  <div className="team-name"> Saif Khan</div>
                  <div className="team-des"> Manager</div>
                  <div className="social-icons">
                    <a href="" target="">
                      <FaTwitter />
                    </a>
                    <a href="" target="">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="team-card">
                  <img
                    src={Team3}
                    className="team-img w-50"
                    alt="client-logo"
                  />
                  <div className="team-name"> Harsh</div>
                  <div className="team-des"> Android Developer</div>
                  <div className="social-icons">
                    <a href="" target="">
                      <FaTwitter />
                    </a>
                    <a href="" target="">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="team-card">
                  <img
                    src={Team4}
                    className="team-img w-50"
                    alt="client-logo"
                  />
                  <div className="team-name"> Sai</div>
                  <div className="team-des"> FullStack Developer</div>
                  <div className="social-icons">
                    <a href="" target="">
                      <FaTwitter />
                    </a>
                    <a href="" target="">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Portfolio">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="team-card">
                  <img
                    src={Team5}
                    className="team-img w-50"
                    alt="client-logo"
                  />
                  <div className="team-name"> Sindhu.K</div>
                  <div className="team-des"> aws & Deveops</div>
                  <div className="social-icons">
                    <a href="/about" target="">
                      <FaTwitter />
                    </a>
                    <a href="/about" target="">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="team-card">
                  <img
                    src={Team6}
                    className="team-img w-50"
                    alt="client-logo"
                  />
                  <div className="team-name"> Sakhi Khan</div>
                  <div className="team-des">Front-End Developer</div>
                  <div className="social-icons">
                    <a href="/about" target="">
                      <FaTwitter />
                    </a>
                    <a href="/about" target="">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default About;
