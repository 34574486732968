import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Layout from "../components/layout/Layout.js";
import {
  FaInstagramSquare,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import "../pages/Contact.css";
import { FaMobileAlt } from "react-icons/fa";
import { FiMapPin, FiMail } from "react-icons/fi";

const Result = ({ success, message }) => {
  return <p className={success ? "confirm" : "error"}>{message}</p>;
};

const Contact = () => {
  const [result, setResult] = useState({ success: false, message: "" });
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });

    // Check if any required fields are empty
    if (!data["from-name"] || !data["from-email"] || !data["message"]) {
      setResult({
        success: false,
        message: "Please fill out all required fields.",
      });
      return;
    }

    emailjs
      .sendForm(
        "service_j26a0g7",
        "template_e6zv9ex",
        form.current,
        "ffSefLeM35Sv0KWBr"
      )
      .then(
        (result) => {
          setResult({
            success: true,
            message:
              "Your Message has been successfully sent. I will contact you soon.",
          });
        },
        (error) => {
          setResult({
            success: false,
            message:
              "There was an error with your submission. Please check your input.",
          });
        }
      );

    e.target.reset();
  };
  return (
    <Layout title={"Decooders/Contact"}>
      <section className="Contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <div className="contact-heading">Let's Talk</div>
              <div className="Paragraph1">
                We'd love to learn more about you and what we can design and
                build together
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Contacts">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 right">
              <p className="contact-info">Contact Info</p>
              <div className="cnticons">
                <FiMapPin />
                <span className="tel-number">
                  Plot no: 727, Flat no: 401, Annamayya Recidency, Road No-38,
                  Ayappa Socity, Madhapur, Hyderabad-500081.
                </span>
              </div>
              <div className="cnticons">
                <FaMobileAlt />
                <span className="tel-number">
                  +91 7799699644
                  <br />
                  +91 9010611618
                </span>
              </div>
              <div className="cnticons">
                <FiMail />
                <span className="tel-number">
                  hello@decooders.in <br />
                  decooders@gmail.com
                </span>
              </div>
              <div className="social-icons">
                <a href="https://www.instagram.com/decooders/" target="_blank">
                  <FaInstagramSquare />
                </a>
                <a href="/" target="">
                  <FaFacebookF />
                </a>
                <a href="https://twitter.com/Decooders" target="_blank">
                  <FaTwitter />
                </a>
                <a href="https://www.linkedin.com/decooders/" target="_blank">
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 left ">
              <div className="contact-info1">Send a Message</div>

              <form ref={form} onSubmit={sendEmail}>
                <div className="mb-3 form-input">
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Username"
                    name="from-name"
                  />
                </div>
                <div className="mb-3 form-input">
                  <input
                    type="number"
                    placeholder="Phone Number"
                    className="form-control"
                    id="exampleInputEmail1"
                    name=""
                  />
                </div>
                <div className="mb-3 form-input">
                  <input
                    type="email"
                    placeholder="Email"
                    className="form-control"
                    id="exampleInputEmail1"
                    name="from-email"
                  />
                </div>
                <div className="mb-3 form-input">
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Subject"
                    name="from-name"
                  />
                </div>
                <div className="mb-3 form-input form">
                  <textarea
                    type="text"
                    name="message"
                    className="form-control"
                    placeholder="message"
                  />
                </div>
                <div className="contact-btn">
                  <input type="submit" value="Send" className="btn2" />
                </div>
                <div className="row">
                  <Result success={result.success} message={result.message} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </Layout>
  );
};

export default Contact;
