import React from "react";
import {
  FaInstagramSquare,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaLink,
} from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import "./Footer.css";
import { Link } from "react-router-dom";
import Footerlogo from "../../assets/logo-main.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6 ft-1">
            <a className="navbar-brand" href="/">
              <img src={Footerlogo} alt="footer-logo" className="footerlogo" />
            </a>

            <p>
              We believe in the power of collaboration. By working closely with
              our clients, we ensure that every project reflects their unique
              vision and goals.
            </p>

            <div className="social-icons">
              <a href="https://www.instagram.com/decooders/" target="_blank">
                <FaInstagramSquare />
              </a>
              <a href="/" target="">
                <FaFacebookF />
              </a>
              <a href="https://twitter.com/Decooders" target="_blank">
                <FaTwitter />
              </a>
              <a href="https://www.linkedin.com/decooders/" target="_blank">
                <FaLinkedinIn />
              </a>
            </div>
            <div className="registration">
              @decooders. Don't Hesitate to reach out
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 ft-3">
            <a
              href="https://www.google.com/maps/place/Decooders/@17.4542004,78.3948603,19z/data=!3m1!4b1!4m6!3m5!1s0x3bcb9185190f5557:0xff255492251e57cb!8m2!3d17.4541991!4d78.395504!16s%2Fg%2F11k4wc_8fr?entry=ttu"
              target="_blank"
            >
              <div className="address">
                <h5>
                  <span>
                    <FaLocationDot />
                  </span>
                  Address
                </h5>
              </div>
              <div className="address-text">
                Flat 401, Annamayya residency, Rd Number 38, Ayyappa Society,
                Madhapur, Hyderabad, Telangana 500081.
              </div>
            </a>
            <div className="Footer-phone">
              <a href="tel:7799699644" target="_blank">
                +91 7799699644
              </a>
            </div>
            <div className="registration">
              <a
                href="mailto:hello@decooders.in?subject=Project Enquiry"
                target="_blank"
              >
                hello@decooders.in
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6 ft-4">
            <div className="contact">
              <h5>
                <span>
                  <FaLink />
                </span>
                Link
              </h5>
            </div>
            <div className="nav-links">
              <Link to="/about">About</Link>
              <br />
              <Link to="/">Projects</Link>
              <br />
              <Link to="/">Services</Link>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6 ft-6">
            <div className="contact">
              <div className="nav-links">
                <Link to="/policy">Privacy Policy</Link>
                <br />
                <Link to="/policy">Terms & Conditions</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
