import React from "react";
import "../layout/Preloader.css";
import { InfinitySpin } from "react-loader-spinner";

const Preloader = () => {
  return (
    <div className="Loader">
      DEC
      <InfinitySpin width="200" color="#000" />
      DERS.
    </div>
  );
};

export default Preloader;
